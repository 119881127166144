import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { IuserDetail } from './login.model';
import { ToastrService } from 'ngx-toastr';
import { LoginResponse } from 'src/app/core/models/auth.models';

@Component({
  selector: 'app-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.scss']
})
/**
 * Login-2 component
 */
export class Login2Component implements OnInit {
  isPasswordShow :boolean=false
  constructor(private formBuilder: FormBuilder,
     private route: ActivatedRoute,
      private router: Router, 
      private authenticationService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    private toastr: ToastrService
    ) { }
  loginForm: FormGroup;
  submitted = false;
  error = '';
  returnUrl: string;

  // set the currenr year
  year: number = new Date().getFullYear();

  ngOnInit(): void {
    document.body.classList.add('auth-body-bg')
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    // reset login status
    // this.authenticationService.logout();
    // get return url from route parameters or default to '/'
    // tslint:disable-next-line: no-string-literal
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  carouselOption: OwlOptions = {
    items: 1,
    loop: false,
    margin: 0,
    nav: false,
    dots: true,
    responsive: {
      680: {
        items: 1
      },
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  /**
   * Form submit
   */
  onSubmit () {
    this.submitted = true;

    // stop here if form is invalid

    if (this.loginForm.invalid) {
      return;
    } else {
      let payload :FormData = new FormData();
      payload.append("email",this.f.email.value);
      payload.append("password",this.f.password.value)
      payload.append("ip_address","192.168.1.125")
      payload.append("device_information","4D:12:12:44|Windows 10XP")
      this.authenticationService.loginService(payload).subscribe({next: async(result:LoginResponse)=> {
        if(result.Status==1) {
          localStorage.setItem("UserInfo",JSON.stringify(result) );
          sessionStorage.setItem("AccessToken",result.token);
        this.router.navigate(['/dashboards/saas']);
          
        }
        else 
        {
            this.toastr.error(result.Message)
        }

        // (await this.authenticationService.getUserProfile(result.userName.toString())).subscribe({next:(res)=> {
        //   console.log(res)
        // },error:(error)=> {
        //   console.log(error)
        //   alert(error)
        // }})

      },error:(error)=> {
        console.log(error)
      }})
      
    }
  }
}
