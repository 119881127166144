import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpRequestService {
  AccessToken: string;
  baseUrl: string = environment.baseUrl;
  constructor(private http: HttpClient) {
    this.AccessToken = sessionStorage.getItem("AccessToken");
  }

  callHttpRequest<T>(
    route: string,
    data: any,
    methodType: string,
    additionalHeaders: any = null
  ) {
    // let httpHeader = `Bearer ${this.AccessToken}`
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${sessionStorage.getItem("AccessToken") ?? ""}`,
    });
    let url = this.baseUrl + "/" + route;
    switch (methodType?.toUpperCase()) {
      case "POST":
        return this.http.post<T>(url, data, { headers: httpHeaders });
      case "GET":
        return this.http.get<T>(url, { headers: httpHeaders });
      case "DELETE":
        return this.http.delete<T>(url, { headers: httpHeaders });
      case "PUT":
        return this.http.put<T>(url, data, { headers: httpHeaders });
      case "OUT":
        return this.http.get<T>(route);
      default:
        break;
    }
  }
}
