import { Injectable } from "@angular/core";

import { getFirebaseBackend } from "../../authUtils";

import { LoginResponse, User } from "../models/auth.models";
import { HttpRequestService } from "src/app/shared/services/http-request.service";
import { IuserDetail } from "src/app/account/auth/login2/login.model";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  user: User;

  constructor(private httpRequest: HttpRequestService) {}

  /**
   * Returns the current user
   */
  public currentUser(): User {
    return getFirebaseBackend().getAuthenticatedUser();
  }

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  login(email: string, password: string) {
    return getFirebaseBackend()
      .loginUser(email, password)
      .then((response: any) => {
        const user = response;
        return user;
      });
  }

  public loginService<IuserDetail>(loginData: any) {
    let url = "login";
    // this.getUserProfile(res?.userName);
    return this.httpRequest.callHttpRequest<LoginResponse>(
      url,
      loginData,
      "POST"
    );
  }

  public ResetPassword(payload) {
    let url = "ForgotPassword";
    return this.httpRequest.callHttpRequest(url, payload, "POST");
    // console.log(res)
    // return res;
  }

  public async getUserProfile(id: string) {
    let url = `RetailerProfileManagement/getRetailerById/${id}`;
    return await this.httpRequest.callHttpRequest(url, null, "GET");
    // console.log(res)
    // return res;
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  register(email: string, password: string) {
    return getFirebaseBackend()
      .registerUser(email, password)
      .then((response: any) => {
        const user = response;
        return user;
      });
  }

  /**
   * Reset password
   * @param email email
   */
  resetPassword(email: string) {
    return getFirebaseBackend()
      .forgetPassword(email)
      .then((response: any) => {
        const message = response.data;
        return message;
      });
  }

  /**
   * Logout the user
   */
  logout() {
    // logout the user
    // getFirebaseBackend().logout();
    sessionStorage.removeItem("AccessToken");
    sessionStorage.removeItem("UserInfo");
  }

  public UserType() {
    let user = localStorage.getItem("UserInfo");
    let userType = JSON.parse(user).Data.usertypename;
    return userType;
  }
  public Userid() {
    let user = localStorage.getItem("UserInfo");
    let userId = JSON.parse(user).Data.id;
    return userId;
  }
}
