import { Injectable } from "@angular/core";
import { HttpRequestService } from "src/app/shared/services/http-request.service";
import {
  IStateModel,
  IcountryModel,
  ImasterDetail,
} from "../models/master.model";

@Injectable({
  providedIn: "root",
})
export class CrmService {
  constructor(private httpRequest: HttpRequestService) {}
  GetMaster() {
    return this.httpRequest.callHttpRequest("getMaster", null, "Get");
  }

  AddDistrict(data: any) {
    return this.httpRequest.callHttpRequest("CreateNewDistrict", data, "Post");
  }
  AddMaster(data: any) {
    return this.httpRequest.callHttpRequest("addNewMaster", data, "Post");
  }

  DeleteMaster(data: any) {
    return this.httpRequest.callHttpRequest("deleteMaster", data, "Post");
  }
  DeleteFolder(data: any) {
    return this.httpRequest.callHttpRequest("DeleteFolder", data, "Post");
  }
  GetCountryList<IcountryModel>() {
    return this.httpRequest.callHttpRequest<IcountryModel>(
      "getCountryList",
      null,
      "Get"
    );
  }
  GetUserTypeList<IcountryModel>(data: any) {
    return this.httpRequest.callHttpRequest<IcountryModel>(
      "getMasterValue?master_id=" + data.id,
      null,
      "Get"
    );
  }
  GetStateList<IStateModel>(data: any) {
    return this.httpRequest.callHttpRequest<IStateModel>(
      "getStateList",
      data,
      "Post"
    );
  }
  GetCityList(data: any) {
    return this.httpRequest.callHttpRequest("getCityList", data, "Post");
  }
  AddMasterValue(data: FormData) {
    return this.httpRequest.callHttpRequest("addNewMasterValue", data, "Post");
  }
  GetMasterValue(data: string) {
    return this.httpRequest.callHttpRequest(
      `getMasterValue?master_id=${data}`,
      null,
      "Get"
    );
  }
  GetFamilyFilterList(id) {
    return this.httpRequest.callHttpRequest(`GetFilterFamilyList?UserId=`+id, null, "Get");
  }
  GetDistrictList() {
    return this.httpRequest.callHttpRequest(`GetDistrictList`, null, "Get");
  }
  GetAllDistrictList() {
    return this.httpRequest.callHttpRequest(`GetAllDistrictList`, null, "Get");
  }
  GetBlockList(data: any) {
    return this.httpRequest.callHttpRequest("GetBlockList", data, "post");
  }
  GetGramPanchayatList(data: any) {
    return this.httpRequest.callHttpRequest("GetGramPanchayat", data, "post");
  }
  GetVillageList(data: any) {
    return this.httpRequest.callHttpRequest("GetVillageList", data, "post");
  }
  DeleteMasterValue(data: FormData) {
    return this.httpRequest.callHttpRequest("deleteMasterValue", data, "post");
  }
  DeleteDistrictValue(data: FormData) {
    return this.httpRequest.callHttpRequest("DeleteDistrict", data, "post");
  }
  UserRegistationSerivce(data: FormData) {
    return this.httpRequest.callHttpRequest("UserRegistration", data, "Post");
  }
  CreateLeadService(data: any) {
    return this.httpRequest.callHttpRequest("CreateLead", data, "Post");
  }
  CreateCNCPChild(data: any) {
    return this.httpRequest.callHttpRequest("CreateCNCPData", data, "Post");
  }
  CreateFolder(data: any) {
    return this.httpRequest.callHttpRequest("CreateFolder", data, "Post");
  }
  UploadFiles(data: any) {
    return this.httpRequest.callHttpRequest("UploadFiles", data, "Post");
  }
  GetLeadService() {
    return this.httpRequest.callHttpRequest(`GetLeadData`, null, "Get");
  }
  GetCNCPData(id) {
    return this.httpRequest.callHttpRequest(
      `GetCNCPData?UserId=` + id,
      null,
      "Get"
    );
  }
  GetDashboardService() {
    return this.httpRequest.callHttpRequest(`Dashboard`, null, "Get");
  }
  GetQuotationService() {
    return this.httpRequest.callHttpRequest(`GetQuotation`, null, "Get");
  }
  GetFileService(data) {
    return this.httpRequest.callHttpRequest(`GetFileList`, data, "Get");
  }

  DeleteCNCPService(data: any) {
    return this.httpRequest.callHttpRequest(`DeleteCNCPData`, data, "POST");
  }
  DeleteQuotationService(data: any) {
    return this.httpRequest.callHttpRequest(`DeleteQuotation`, data, "POST");
  }
  DeleteFileService(data: any) {
    return this.httpRequest.callHttpRequest(`DeleteFile`, data, "POST");
  }
  DeleteUser(data: any) {
    return this.httpRequest.callHttpRequest(`DeleteUser`, data, "POST");
  }
  AddLeadDiscussion(data: any) {
    return this.httpRequest.callHttpRequest(`AddLeadDiscussion`, data, "POST");
  }
  GetUserList(data: any) {
    return this.httpRequest.callHttpRequest(`GetUserList`, data, "Get");
  }
  GetFolderList(data: any) {
    return this.httpRequest.callHttpRequest(`GetFolderList`, data, "Get");
  }
  CreateQuatationService(data: any) {
    return this.httpRequest.callHttpRequest("AddQuotation", data, "POST");
  }
  addContactService(data: any) {
    return this.httpRequest.callHttpRequest("AddContact", data, "POST");
  }
  addNewFamily(data: any) {
    return this.httpRequest.callHttpRequest("CreateFamily", data, "POST");
  }
  ChangePasswordService(data: FormData) {
    return this.httpRequest.callHttpRequest("UpdatePassword", data, "POST");
  }
  GetContactService(data: any) {
    return this.httpRequest.callHttpRequest("GetContact", data, "Get");
  }
  GetFamilyService(data: any) {
    return this.httpRequest.callHttpRequest(
      "GetFamilyList?UserId=" + data,
      data,
      "Get"
    );
  }
  ForgotPasswordService(data: FormData) {
    return this.httpRequest.callHttpRequest("ForgotPassword", data, "Post");
  }
  ResetPassword(data: FormData) {
    return this.httpRequest.callHttpRequest("ChangePassword", data, "Post");
  }

  DeleteFamily(data: any) {
    return this.httpRequest.callHttpRequest("DeleteFamily", data, "Post");
  }
  GetPanchayatDashboard(data) {
    return this.httpRequest.callHttpRequest("GetPanchayatDashboard", data, "Post");
  }
  GetChangeDateChart(data) {
    return this.httpRequest.callHttpRequest("GetChangeDateChart", data, "Post");
  }
}
