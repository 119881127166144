import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT, JsonPipe } from '@angular/common';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { IuserDetail } from 'src/app/account/auth/login2/login.model';
import { LoginResponse } from 'src/app/core/models/auth.models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CrmService } from 'src/app/core/services/crm.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  element;
  cookieValue;
  flagvalue;
  submitted:boolean
  countryName;
  valueset;
  formData:FormGroup
  userInfo:LoginResponse;
  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: AuthenticationService,

  private modalService: NgbModal,

              private authFackservice: AuthfakeauthenticationService,
              public languageService: LanguageService,
              public translate: TranslateService,
              private crmService: CrmService,
              public _cookiesService: CookieService,
              public toastr:ToastrService) {

                this.formData = new FormGroup({
                  OldPassword:new FormControl("",[Validators.required,]),
                  ConfirmPassword:new FormControl("",[Validators.required]),
                  NewPassword:new FormControl("",[Validators.required]),

                })
  }

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;
    let userdatastring= localStorage.getItem("UserInfo");;
    console.log(sessionStorage.getItem("AccessToken"))
    console.log(userdatastring)
    this.userInfo  =JSON.parse(userdatastring);
    console.log("userinfo",this.userInfo)
    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    // if (environment.defaultauth === 'firebase') {
    //   this.authService.logout();
    // } else {
    //   this.authFackservice.logout();
    // }
    this.authService.logout();
    this.router.navigate(['/account/login-2']);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
  ChangePassword(exlargeModal:any) {
    this.modalService.open(exlargeModal, {
      size: "l",
      centered: true,
      scrollable: true,})
  }
  submit() {
    let payLoad:FormData = new FormData()
    this.submitted = true;
    if(!this.formData.valid) {
      this.toastr.error("All fields are required");
      return;
    }
    if(this.formData.controls.ConfirmPassword.value == this.formData.controls.newpassword.value) {
      this.toastr.error("confirm password should be same as new password");
     
      return;
    }
    payLoad.append("id",this.userInfo.Data.id.toString())
    payLoad.append("oldpassword",this.formData.controls.OldPassword.value)
    payLoad.append("newpassword",this.formData.controls.ConfirmPassword.value)
    this.crmService.ChangePasswordService(payLoad).subscribe({
      next: (response:any)=>{
        if(response.Status==1) {
          this.toastr.success(response.Message)
          this.modalService.dismissAll()
        }
        else {
          this.toastr.error(response.Message)
        }
      },
      error:(err)=> {
        this.toastr.error(err)

      }
    })
  }
}
