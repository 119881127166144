import { Component, OnInit, AfterViewInit, ElementRef, Renderer2 } from '@angular/core';
import { TOPBAR } from "../layouts.model";
import { EventService } from '../../core/services/event.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-horizontal',
  templateUrl: './horizontal.component.html',
  styleUrls: ['./horizontal.component.scss']
})

/**
 * Horizontal-layout component
 */
export class HorizontalComponent implements OnInit, AfterViewInit {

  topbar: string;
  currentUrl:any;
  currentClass:any='';
  constructor(private router: Router,private eventService: EventService,elementRef: ElementRef, renderer: Renderer2) {
    if(this.router.url=='/crm/contact-create'||this.router.url=='/crm/lead-creation'||this.router.url=='/crm/generate-quotation'||this.router.url=='/crm/manage-quotation'||this.router.url=='/crm/manage-leads'||this.router.url=='/crm//contact-list'){
      this.currentClass=((this.router.url=='/crm/contact-create'||this.router.url=='/crm//contact-list')?'background-pink':((this.router.url=='/crm/lead-creation'||this.router.url=='/crm/manage-leads')?'background-yellow':((this.router.url=='/crm/generate-quotation'||this.router.url=='/crm/manage-quotation')?'background-blue':'')))
    }else{
      this.currentClass='';
    }
    renderer.listen(elementRef.nativeElement, 'click', (event) => {
      // Do something with 'event'
      if(this.router.url=='/crm/contact-create'||this.router.url=='/crm/lead-creation'||this.router.url=='/crm/generate-quotation'||this.router.url=='/crm/manage-quotation'||this.router.url=='/crm/manage-leads'||this.router.url=='/crm//contact-list'){
      this.currentClass=((this.router.url=='/crm/contact-create'||this.router.url=='/crm//contact-list')?'background-pink':((this.router.url=='/crm/lead-creation'||this.router.url=='/crm/manage-leads')?'background-yellow':((this.router.url=='/crm/generate-quotation'||this.router.url=='/crm/manage-quotation')?'background-blue':'')))
    }else{
      this.currentClass='';
    }
    })
   }

  ngOnInit() {

    this.topbar = TOPBAR;

    this.eventService.subscribe('changeTopbar', (topbar) => {
      this.topbar = topbar;
      this.changeTopbar(this.topbar);
    });

    document.body.setAttribute('data-layout', 'horizontal');
    document.body.removeAttribute('data-sidebar');
    document.body.removeAttribute('data-layout-size');
    document.body.removeAttribute('data-keep-enlarged');
    document.body.removeAttribute('data-sidebar-small');    

    this.changeTopbar(this.topbar);
  }

  ngAfterViewInit() {
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  changeTopbar(topbar: string) {
    switch (topbar) {
      case "light":
        document.body.setAttribute("data-topbar", "light");
        break;
      case "dark":
        document.body.setAttribute("data-topbar", "dark");
        break;
      case "colored":
        document.body.setAttribute("data-topbar", "colored");
        break;
      default:
        document.body.setAttribute("data-topbar", "dark");
        break;
    }
  }

}
